@import './src/sass/all.scss';
.header {
  text-align: center;
  padding-top: 25px;
  display: none;
  img {
    height: 60px;
  }
}

@media only screen and (min-width: 600px) {
  .header {
    display: block;
  }
}
