@import './src/sass/all.scss';
.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: $grey-dark-3;
  justify-content: space-evenly;
  height: 100%;
  .mainContent {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}
