@import './src/sass/all.scss';
.memberCard {
  position: relative;
  height: 180px;
  min-width: 290px;
  padding: 20px;
  border-radius: 10px;
  &.tierGold {
    background-image: url('../../../assets/subscription/member_card_background_gold.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  &.tierBase {
    background-image: './assets/subscription/member_card_background.png';
  }
  p {
    margin: 0;
    color: $white;
    text-transform: capitalize;
  }
  .memberCardHeader {
    display: flex;
    margin-bottom: 30px;
    .artistImage {
      width: 50px;
      height: 50px;
      border: 3px solid $white;
      overflow: hidden;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      img {
        height: 100%;
      }
    }
    .communityDescription {
      padding-left: 18px;
      text-align: justify;

      .artistName {
        font-size: 18px;
        font-weight: 700;
      }
      .officialMember {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
  .memberCardBody {
    text-align: justify;
    .membershipDetails {
      padding-top: 3px;
      display: flex;
      justify-content: space-between;
      text-transform: none;
      font-size: 11px;
      p:first-child {
        color: $primary;
      }
    }
    .fanUsername {
      font-size: 13px;
      font-weight: 700;
    }
  }

  .memberCardFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 27px;
    border-radius: 0 0 10px 10px;
    background-color: $orange;
    font-size: 11px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) {
  .memberCard {
    min-width: 321px;
  }
}
