@import './src/sass/all.scss';
.subscriptionContainer {
  margin: 0;
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $grey-dark-3;
  color: $white;
  .subsHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 32px;
    .title {
      padding: 32px 0;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .subsFeaturesContainer {
    max-width: 385px;
    background-color: $grey-dark-1;
    border-radius: 8px;
    margin: 22px 16px;
    padding: 24px;
    .separator {
      width: 30px;
      margin: auto;
      height: 2px;
      background-color: $orange;
      border: 0;
      margin-bottom: 12px;
    }
    .subtitleFeatures {
      text-align: center;
      margin-bottom: 8px;
    }
    .subsFeatures {
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      .featureImage {
        margin: 0;
        display: flex;
        margin-right: 16px;
        height: 40px;
        img {
          object-fit: cover;
        }
      }
      .featureTitle {
        margin: 0;
        font-size: 15px;
        font-weight: 600;
      }
      .featureSubtitle {
        margin: 0;
        font-size: 13px;
        font-weight: 400;
        color: $grey-blue;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .subsFooter {
    width: 100%;
    max-width: 600px;
    border-radius: 8px 8px 0 0;
    padding: 30px 25px 41px 25px;
    background: $grey-dark-1;
    .priceContainer {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      .price {
        font-size: 26px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
}

.greyText {
  margin-top: 5px;
  font-size: 13px;
  color: $grey-blue;
}

@media only screen and (min-width: 600px) {
  .subscriptionContainer {
    border-radius: 8px;
    padding-top: 20px;
    background-color: $grey-dark-2;
    .subsFooter {
      border-radius: 8px;
      width: 600px;
    }
  }
}
