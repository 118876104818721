@import './src/sass/all.scss';
.inputContainer {
    position: relative;
    width: 100%;
    .input {
        box-sizing: border-box;
        padding: 14px 15px;
        border-radius: 8px;
        width: 100%;
        background-color: $grey-light;
        border: none;
        &:focus {
            outline: none;
        }
        &.withIcon {
            padding: 14px 35px 14px 15px;
        }
        &.error {
            border: 1px solid red;
            animation: shakeError 0.4s 1 linear;
        }
    }
    
    .icon {
        position: absolute;
        right: 10px;
        top: 14px;
        cursor: pointer;
    }
}

@keyframes shakeError {
    0% { transform: translate(30px); }
    20% { transform: translate(-30px); }
    40% { transform: translate(15px); }
    60% { transform: translate(-15px); }
    80% { transform: translate(8px); }
    100% { transform: translate(0px); }
}