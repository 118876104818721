// Colors
$colkie-purple: #8651ff;
$purple-medium: #5527bd;
$purple-dark: #38187f;
$purple-light: #d0bbff;
$purple-light-2: #f1ebff;
$grey-blue: #a7a7a7;
$grey-light: #f2f2f2;
$grey-medium: #d9d9d9;
$grey-dark: #747474;
$grey-dark-1: #424242;
$grey-dark-2: #383838;
$grey-dark-3: #282828;
$red: #ed4848;
$orange: #eebf45;
$white: #fff;
$white-container: #fafafa;
$black: #020202;

$text: #161616;
$borders: rgba(113, 128, 150, 0.38);
$shadow: rgba(212, 217, 232, 0.5);
$shadow-dark: rgba(190, 197, 220, 0.4);
$background: #fcfcfc;

$primary: $colkie-purple;
$primary-contrast: $white;
$secondary: $purple-light;
