@import './src/sass/all.scss';
.btn {
  width: 100%;
  padding: 12px 32px;
  border-radius: 8px;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  background-color: $primary;
  color: $primary-contrast;
  display: flex;
  justify-content: center;
  .icon {
    margin-right: 10px;
    display: inline-flex;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.buttonDisabled {
    background-color: $purple-light;
    cursor: not-allowed;
  }
}
