@import './src/sass/all.scss';
.fullScreen_template {
  position: relative;
  height: 100%;
  overflow: hidden;
  color: $white;
  background-color: $grey-dark-3;
  .image_bottom,
  .image_top {
    display: none;
  }
  .error404 {
    height: calc(100% - 84ox);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    .container {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: space-between;
      text-align: center;
      .message {
        flex-grow: 1;
        .error {
          font-size: 4rem;
          margin-bottom: 10px;
        }
        .title {
          font-size: 2rem;
          margin-bottom: 30px;
        }
        .subtitle {
          font-size: 1.1rem;
          margin-bottom: 30px;
        }
        .backButton {
          width: 80%;
          display: unset;
        }
      }
      .illustration {
        width: 100%;
        height: auto;
        animation: main_illustration_animation 8s ease-in-out infinite;
        transform: rotate(-5deg);
        .imgCloud {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .fullScreen_template {
    .image_top,
    .image_bottom {
      position: absolute;
      display: block;
    }
    .image_top {
      animation: displaceTop 6s ease-in-out infinite;
    }
    .image_bottom {
      right: 0;
      bottom: 0;
      animation: displaceBottom 10s ease-in-out infinite;
    }
    .error404 {
      .container {
        max-width: 1200px;
        flex-direction: row;
        .message {
          max-width: 439px;
          margin-right: 80px;
        }
        .illustration {
          max-width: 630px;
        }
      }
    }
  }
}

@keyframes displaceTop {
  50% {
    transform: translate(-100px, -20px) scale(1.2);
    opacity: 0.5;
  }
}

@keyframes displaceBottom {
  30% {
    transform: translate(100px, 60px);
    opacity: 0.7;
  }
}

@keyframes main_illustration_animation {
  50% {
    transform: rotate(5deg);
  }
}
