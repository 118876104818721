@import './src/sass/all.scss';
$margin-bottom: 20px;

.companionElement {
  display: none;
  border-radius: 8px 0 0 8px;
}
.signup {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  max-width: 320px;
  color: $white;
  .title,
  .subtitle {
    text-align: center;
    margin-bottom: $margin-bottom;
  }
  .blackButton {
    background-color: $black;
    margin-bottom: $margin-bottom;
  }
}

@media only screen and (min-width: 600px) {
  .signup {
    max-width: none;
    .buttonsContainer {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      > * {
        width: 48%;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .companionElement {
    display: flex;
    align-self: center;
    padding: 65px 33px 32px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $purple-medium;
    color: $white;
    text-align: center;
    img {
      max-height: 383px;
    }
  }
}
