@import './src/sass/all.scss';
$rand: random(
  $limit: 1,
);
.subscription_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: $grey-dark-3;
  color: $white;
  .subscriptionContainer {
    padding: 0 16px;
    width: 100%;
    height: calc(100% - 148px);
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 48px;
      color: $purple-light;
    }
    .subtitle {
      text-align: center;
      font-size: 24px;
    }
    .artistName {
      color: $primary;
      font-size: 1.2rem;
      margin: 0;
    }
    .capitalize {
      text-transform: capitalize;
    }
    .btnOpenApp {
      max-width: 300px;
    }
    .text {
      font-size: 16px;
      text-align: center;
    }
    .imageCoverContainer {
      max-width: 500px;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & > * {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .subscriptionContainer {
    padding: 0 16px;
    .title {
      font-size: 48px;
    }
    .subtitle {
      font-size: 32px;
    }
    .text {
      font-size: 20px;
    }
    .colkieLogo {
      display: none;
    }
  }
}
