@import './src/sass/all.scss';
.container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
  > * {
    flex: 1;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    background-color: $grey-dark-2;
    margin: 0 auto;
    border-radius: 8px;
    max-width: 960px;
    height: auto;
    width: auto;
    align-items: center;
  }
}
