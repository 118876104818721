@import './src/sass/all.scss';
.companionElement {
  display: none;
  border-radius: 8px 0 0 8px;
}
.login {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  max-width: 320px;
  :not(:last-child) {
    margin-bottom: 20px;
  }
  .title {
    color: $purple-light;
    margin-bottom: 60px;
  }

  input[type='text'],
  input[type='password'] {
    background-color: $grey-dark-1;
    color: $white;
  }
}

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (min-width: 600px) {
  .login {
    max-width: none;
    width: 70%;
  }

  .loginContainer {
    flex-direction: row;
  }

  .colkieLogo {
    display: none;
  }
  .companionElement {
    display: flex;
    align-self: center;
    padding: 65px 28px 32px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $purple-medium;
    color: $white;
    text-align: center;
    .title:first-child {
      font-weight: normal;
    }

    .subtitle {
      margin-top: 20px;
      width: 80%;
    }
  }
}
